import { PreviewType, WebstoreConfigurationColor, WebstoreConfigurationStep, WebstoreConfigurationTradingDay } from "@/enums"
import { EditItem } from "@/state/builderMenu"
import { Contact, Hero, SocialPages, TradingHours, WebstoreConfig, WebstoreShipping } from "@/types"
import {
	aboutUsValidationSchema,
	contactValidationSchema,
	heroValidationSchema,
	socialsValidationSchema,
} from "@/utils/validationSchemas"
import { atom } from "jotai"
import { atomWithFormControls, atomWithValidate } from "jotai-form"
import { focusAtom } from "jotai-optics"

/**
 * Mode determines whether some components are editable, the appearance of the editor,
 * whether certain functionlaties are available, etc.
 */
export const builderModeEnabledAtom = atom(false)

const openTime = new Date("01-01-2025") // fix default so draft vs live comparison is consistent
const closeTime = new Date(openTime)
closeTime.setDate(closeTime.getDate() + 1)

const defaultTradingHour = {
	day: WebstoreConfigurationTradingDay.MONDAY,
	open: openTime.toISOString(),
	close: closeTime.toISOString(),
}

const defaultShipping: WebstoreShipping = {
	flatRate: 0,
	overrideFlatRateWhenOverAmount: false,
	overrideAmount: 0,
	optionToCollect: false,
	allowCustomerInstruction: false,
}
/**
 * The initial state of the webstore configuration.
 * This value should never really be used, as it is only used to initialize the webstore configuration.
 * This should always be overridden by the actual webstore configuration after fetching it.
 */
export const initialWebconfigDefaults: WebstoreConfig = {
	currentStep: WebstoreConfigurationStep.AESTHETIC_SELECTION,
	aboutUs: "",
	contact: {
		address: "",
		email: "",
		phone: "",
		whatsapp: "",
	},
	colorScheme: WebstoreConfigurationColor.WHITE,
	hero: {
		header: "",
		subheading: "",
		imageUrl: "",
	},
	tradingHours: [defaultTradingHour],
	shipping: defaultShipping,
}
export const builderErrorStateAtom = atom<Record<EditItem, boolean | undefined>>({} as Record<EditItem, boolean | undefined>)
/**
 * The webstore configuration for the builder i.e. editing capabilities.
 * This config should only facilitate the builder experience.
 * DO NOT use this for the public site.
 */
export const builderWebconfigStateAtom = atom<WebstoreConfig>({ ...initialWebconfigDefaults })

export const webstoreNameAtom = atom("")

export const previewTypeAtom = atom<PreviewType>(PreviewType.Desktop)

export const builderHeroAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("hero").optional())

export const builderCatalogueItemsAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("catalogueItems"))

export const builderAboutUsAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("aboutUs").optional())

export const colourThemeAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("colorScheme").optional())

export const socialsAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("socialPages").optional())

export const fullRefreshAtom = atom<boolean>(true) // default to true to force a refresh on first load

// a bit of hack here - ideally using the touched state from formControlAtom
// other components could take advantage of the fact that a form was being used to update
export const touchedAtom = atom<boolean>(false)

const formCurrentStepAtom = atomWithValidate<WebstoreConfigurationStep>(WebstoreConfigurationStep.AESTHETIC_SELECTION, {
	validate: async (v) => {
		return v
	},
})

const formAboutUsAtom = atomWithValidate<string>("", {
	validate: async (v) => {
		await aboutUsValidationSchema.validate(v)
		return v
	},
})

// Initial values for the form
const initialContactValue: Contact = {
	address: "1 Nokwe Avenue, Umhlanga Ridge, Durban, 4100",
	email: "support@ikhokha.com",
	phone: "+27721144225",
	whatsapp: "+27731122336",
}

const formContactAtom = atomWithValidate<Contact>(initialContactValue, {
	validate: async (v) => {
		await contactValidationSchema.validate(v)
		return v
	},
})

const formColorSchemeAtom = atomWithValidate<WebstoreConfigurationColor>(WebstoreConfigurationColor.WHITE, {
	validate: async (v) => {
		return v
	},
})

const formHeroAtom = atomWithValidate<Hero>(
	{
		header: "",
		subheading: "",
	},
	{
		validate: async (v) => {
			await heroValidationSchema.validate(v)
			return v
		},
	},
)

const formSocialsAtom = atomWithValidate<SocialPages>(
	{
		facebook: "",
		instagram: "",
		tiktok: "",
	},
	{
		validate: async (v) => {
			await socialsValidationSchema.validate(v)
			return v
		},
	},
)

const formTradingHoursAtom = atomWithValidate<TradingHours[]>([defaultTradingHour], {
	validate: async (v) => {
		return v
	},
})

export const formControlAtom = atomWithFormControls({
	currentStep: formCurrentStepAtom,
	aboutUs: formAboutUsAtom,
	contact: formContactAtom,
	colorScheme: formColorSchemeAtom,
	hero: formHeroAtom,
	tradingHours: formTradingHoursAtom,
	socialPages: formSocialsAtom,
})

/**
 * Manages the state used to display a modal containing media links (Email/WhatsApp)
 * and the downloading of a QR Code linking to the webstore
 */
const publishedModalUIStateAtom = atom({
	openPublishedModal: false,
	openDownloadQRCodeModal: false,
})

export const openPublishedModalAtom = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("openPublishedModal"))

export const openDownloadQRCodeModalAtom = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("openDownloadQRCodeModal"))
