import { User } from "@/types"
import { extendedFetch } from "@/utils"

export const getUserInfoFromCookies = async (): Promise<User> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/user/v1/users/current`
	const result = await extendedFetch({ url })

	const response = await result.json()

	return response.data as User
}
