import { useUserDetails } from "@/hooks/user"
import { useGetWebstoreConfigByOwner } from "@/hooks/webstore"
import { formControlAtom } from "@/state"
import { Contact } from "@/types"
import { displayAddress } from "@/utils/address"
import { useAtomValue } from "jotai"
import { useEffect } from "react"
import { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input"

export const useContactValues = (touched = false) => {
	const { owner, store } = useUserDetails()

	const { data } = useGetWebstoreConfigByOwner(owner ?? "")

	const contactDraft = data?.webstoreConfigDraft?.contact

	const { handleOnChange, values } = useAtomValue(formControlAtom)

	const { contact } = values
	const { address: formAddress, email: formEmail, phone: formPhone, whatsapp: formWhatsapp } = contact as Contact

	const initialAddress = contactDraft?.address ?? displayAddress(store?.address) ?? formAddress
	const initialEmail = contactDraft?.email ?? store?.email ?? formEmail
	const initialPhone = contactDraft?.phone ?? store?.phoneNumber ?? formPhone
	const initialWhatsapp = contactDraft?.whatsapp ?? store?.phoneNumber ?? formWhatsapp

	const getFormattedNumber = (number?: string) => {
		if (!parsePhoneNumber(number ?? "")) {
			return number ? `+${getCountryCallingCode("ZA")}${number}` : ""
		} else return number
	}

	useEffect(() => {
		// on initial load, update the atom
		const updatedContact = {
			address: touched ? formAddress : initialAddress,
			email: touched ? formEmail : initialEmail,
			phone: touched ? formPhone : getFormattedNumber(initialPhone),
			whatsapp: touched ? formWhatsapp : getFormattedNumber(initialWhatsapp),
		}

		const update = handleOnChange("contact")
		update(updatedContact)
	}, [])

	return {
		address: formAddress,
		email: formEmail,
		phone: formPhone,
		whatsapp: formWhatsapp,
	}
}
