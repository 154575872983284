/**
 * Used to displayed formatted duration value for a service
 * @param duration
 */
export const getDurationText = (duration: number): string => {
  if (duration === 0) return '-'

  let time = ''
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  if (hours > 0 && hours < 2) time += `${hours} hr`
  if (hours > 1) time += `${hours} hrs`
  if (hours >= 1 && minutes > 0) time += ' '
  if (minutes > 0) time += `${minutes} mins`
  return time
}

const currencyFormatter = Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'ZAR',
})

/**
 * Used to display formatted price value for a catalogue item
 * @param price
 */
export const getRandCurrencyText = (price: number): string => {
  if (price === 0) return ''
  return `R ${currencyFormatter.format(price).replace('ZAR', '').replace(',', '.').trim()}`
}

/**
 * Generates a full image URL using a given image path and a base CloudFront URL.
 *
 * @param {string | undefined} imagePath - The path of the image. If undefined, the hook will return an empty string.
 * @returns {string} The full image URL if imagePath is defined, otherwise an empty string.
 */
export const getCloudfrontImageUrl = (imagePath: string | undefined): string => (
  imagePath ? `${process.env.NEXT_PUBLIC_MY_PRODUCTS_AND_SERVICES_IMAGES_CLOUDFRONT}/${imagePath}` : ''
)

/**
 * Converts the value to cents or ZAR. The function does not take into account the currency
 * the value is currently in. It simply converts it to the other currency chosen.
 * @param value The value to convert
 * @param currency Choose the currency to convert to. Either 'RAND' or 'CENT'
 * @returns
 */
export const zarConverter = (value: number, currency: 'RAND' | 'CENT') => (
  currency === 'RAND' ? value / 100 : Math.trunc(value * 100)
)

export const defaultCategory = 'All categories'

export const defaultCatalogueType = 'all'

export const defaultPageSize = 20
