import { SideMenuItem } from "@/enums"
import { atomWithReducer } from "jotai/utils"

export enum ActionType {
	EditItem = "EDIT_ITEM",
	Close = "CLOSE",
	SideMenuItemSelected = "SIDE_MENU_ITEM_SELECTED",
}

export enum EditItem {
	Logo = "Logo",
	CatalogueItems = "Catalogue_Items",
	HeroImage = "Hero_Image",
	HeroContent = "Hero_Content",
	Colours = "Colours",
	AboutUs = "About_Us",
	Socials = "Socials",
	StoreDetails = "Store_Details",
	BusinessHours = "Business_Hours",
	ReturnPolicy = "Return_Policy",
	DeliveryOptions = "Delivery_Options",
	None = "None",
}

export enum ItemState {
	Untouched = "Untouched",
	Completed = "Completed",
	Error = "Error"
}

export enum ActionPanelOpen {
	True = "true",
	False = "false",
}

type Payload = EditItemPayload | SideMenuItem

type EditItemPayload = {
	element?: HTMLElement
	editItemType: EditItem
}

type Action = {
	type: ActionType
	payload?: Payload
}

type State = {
	activeMenuItem: SideMenuItem
	actionPanelOpen: boolean
	activeEditingElement?: HTMLElement
	activeEditingItem: EditItem
}
const initialState: State = {
	activeMenuItem: SideMenuItem.Setup_Guide,
	actionPanelOpen: true,
	activeEditingItem: EditItem.None,
}

const editItemActionHandler = (state: State, payload: EditItemPayload): State => {
	const { element, editItemType } = payload
	const newState = { ...state }
	switch (editItemType) {
		case EditItem.CatalogueItems:
		case EditItem.HeroContent:
		case EditItem.AboutUs:
		case EditItem.StoreDetails:
		case EditItem.BusinessHours:
		case EditItem.Socials:
		case EditItem.ReturnPolicy:
			newState.activeMenuItem = SideMenuItem.Settings
			newState.actionPanelOpen = true
			break
		case EditItem.Colours:
			newState.activeMenuItem = SideMenuItem.Colour
	}
	return { ...newState, activeEditingElement: element, activeEditingItem: editItemType }
}

const reducer = (state: State, { payload, type }: Action) => {
	switch (type) {
		case ActionType.SideMenuItemSelected:
			return { ...state, activeMenuItem: payload as SideMenuItem, actionPanelOpen: true }
		case ActionType.EditItem:
			return editItemActionHandler(state, payload as EditItemPayload)
		case ActionType.Close:
			return { ...state, actionPanelOpen: false, activeMenuItem: SideMenuItem.None }
		default:
			return state
	}
}
export const builderMenuReducerAtom = atomWithReducer(initialState, reducer)
