import { useGetWebstoreConfig } from "@/hooks/webstore"
import { formControlAtom } from "@/state"
import { Hero } from "@/types"
import { useAtomValue } from "jotai"
import React from "react"

const defaultAboutUs = (name: string) => `Welcome to ${name}. We're about bringing you the best products, services, and a smooth shopping experience. Please feel free to reach out if you have any questions.`

export const useAboutUsValues = (touched = false) => {
  const { webstoreName, webstoreConfigDraft } = useGetWebstoreConfig()

  const aboutUsDraft = webstoreConfigDraft?.aboutUs

  const { handleOnChange, values } = useAtomValue(formControlAtom)

  const formAboutUs = values.aboutUs as Hero

  const initialAboutUs = aboutUsDraft ?? defaultAboutUs(webstoreName ?? '') ?? formAboutUs

  React.useEffect(() => {
    const updateAboutUsContent = touched ? formAboutUs : initialAboutUs
    const update = handleOnChange("aboutUs")
    update(updateAboutUsContent)
  }, [])

  return { aboutUs: formAboutUs }
}
