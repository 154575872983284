import { Routes, WebstoreConfigurationStep } from "@/enums"
import { GetWebstoreConfigResponse } from "@/types"
import { useRouter } from "next/navigation"
import { useEffect } from "react"

/**
 * Custom hook to redirect the user to the last step in the process they were on.
 *
 * @param {GetWebstoreConfigResponse | null} webstoreConfigData - The webstore configuration data.
 */
export const useRedirectToCurrentStep = (webstoreConfigData?: GetWebstoreConfigResponse | null) => {
	const router = useRouter()

	useEffect(() => {
		if (webstoreConfigData?.webstoreConfigDraft?.currentStep === WebstoreConfigurationStep.AESTHETIC_SELECTION) {
			router.push(`/${webstoreConfigData?.webstoreName}${Routes.Manage_Builder}`)
		} // do nothing otherwise
	}, [webstoreConfigData, router])
}
