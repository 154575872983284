/**
 * This function removes the 'data:image/jpeg;base64,' prefix from a base64 string.
 * If the prefix is not present, it returns the original string.
 *
 * @param {string} base64String - The base64 string from which to remove the prefix.
 * @returns {string} - The base64 string without the prefix.
 */
const removeBase64Prefix = (base64String: string) => {
    const prefix = 'data:image/jpeg;base64,';

    // Check if the string starts with the expected prefix
    if (base64String.startsWith(prefix)) {

      // Remove the prefix
      return base64String.slice(prefix.length);
    }

    // If the prefix is not present, return the original string
    return base64String;
  };

export default removeBase64Prefix;