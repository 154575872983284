import { matchIsValidTel, MuiTelInputContinent } from "mui-tel-input"
import { SocialLinks } from "social-links"
import * as Yup from "yup"
const socialLinks = new SocialLinks()

const continents: MuiTelInputContinent[] = ["AF"]

const specialCharactersValidationSchema = Yup.string().test("matches-regex", "No special characters are allowed", (value) => {
	if (!value) return true
	const regex = /^[a-zA-Z0-9.,'&%-\s_]*$/
	return regex.test(value)
})

export const heroValidationSchema = Yup.object().shape({
	header: specialCharactersValidationSchema,
	subheading: specialCharactersValidationSchema,
})

export const contactValidationSchema = Yup.object().shape({
	address: Yup.string(), // Optional by default
	email: Yup.string()
		.email("Please enter a valid email")
		.required("Email is required")
		.max(50, "The email entered cannot be more than 50 characters."),
	phone: Yup.string()
		.required("Please enter a valid phone number")
		.test("is-valid-phone", "Please enter a valid phone number", function (value) {
			if (!value) return true // Skip the phone validation if the value is empty or null: allow component to handle
			return matchIsValidTel(value, { continents })
		}),
	whatsapp: Yup.string().test("is-valid-phone", "Please enter a valid phone number", function (value) {
		if (!value) return true // Skip the phone validation if the value is empty or null: allow component to handle
		return matchIsValidTel(value, { continents })
	}),
})
export const aboutUsValidationSchema = specialCharactersValidationSchema

const facebookErrorMessage = "Please enter a valid Facebook URL."
const instragramErrorMessage = "Please enter a valid Instagram URL."
const tiktokErrorMessage = "Please enter a valid TikTok URL."
export const socialsValidationSchema = Yup.object().shape({
	facebook: Yup.string()
		.optional()
		.url(facebookErrorMessage)
		.test("is-facebook-url", facebookErrorMessage, (value) => {
			if (!value) return true
			const profile = socialLinks.detectProfile(value)
			if (profile !== "facebook") return false
			return socialLinks.isValid(profile, value)
		}),
	instagram: Yup.string()
		.optional()
		.url(instragramErrorMessage)
		.test("is-instagram-url", instragramErrorMessage, (value) => {
			if (!value) return true
			const profile = socialLinks.detectProfile(value)
			if (profile !== "instagram") return false
			return socialLinks.isValid(profile, value)
		}),
	tiktok: Yup.string()
		.optional()
		.url(tiktokErrorMessage)
		.test("is-tiktok-url", tiktokErrorMessage, (value) => {
			if (!value) return true
			const profile = socialLinks.detectProfile(value)
			if (profile !== "tiktok") return false
			return socialLinks.isValid(profile, value)
		}),
})
