/**
 * Converts a base64 string to a Blob.
 *
 * @param {string} base64String - The base64 string to convert.
 * @param {string} [contentType='image/jpeg'] - The MIME media type of the Blob.
 * @param {number} [sliceSize=512] - The size of each slice.
 * @returns {Blob} The Blob created from the base64 string.
 */
const base64JpegToBlob = (
  base64String: string,
  contentType = 'image/jpeg',
  sliceSize = 512
): Blob => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0, len = byteCharacters.length; offset < len; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = Array.from({length: slice.length}, (_, i) => slice.charCodeAt(i));
    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: contentType });
};

export default base64JpegToBlob;