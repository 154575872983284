import { throwIfNot2xx } from "@/api/helper/helper"
import { PermalinkStatus, PermalinkType, WebstoreConfigurationStep } from "@/enums"
import {
	CreatePermalinkResponse,
	ECommStoreNameCheckResponse,
	GetImageUploadUrlsResponse,
	GetPermalinkResponse,
	GetWebstoreConfigResponse,
	UpdatePermalinkResponse,
	WebstoreConfig,
} from "@/types"
import { defaultFetchOptions, extendedFetch } from "@/utils"
import base64JpegToBlob from "@/utils/base64ToBlob"
import removeBase64Prefix from "@/utils/removeBase64Prefix"

/**
 * Primarily used for the store_setup flow. Shouldn't be used for other purposes.
 */
export const getEcommStorenameAvailableFromCookies = async (
	merchantId: string,
	storeId: string,
	newStoreName: string,
): Promise<boolean> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/Merchant-${merchantId}-${storeId}/ecommStoreName/${newStoreName}`
	const result = await extendedFetch({
		url,
	})

	const response = (await result.json()) as ECommStoreNameCheckResponse

	return response.isAvailable
}

export const getWebstoreConfigByOwner = async (owner: string): Promise<GetWebstoreConfigResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/${owner}/webstoreConfig`
	const result = await extendedFetch({ url })

	const response = (await result.json()) as GetWebstoreConfigResponse
	return response
}

export const updateWebstoreConfig = async (
	owner: string,
	webstoreName: string,
	config: WebstoreConfig,
	status: PermalinkStatus,
): Promise<UpdatePermalinkResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/${owner}/webstore/${webstoreName}`
	const result = await extendedFetch({
		url,
		options: {
			...defaultFetchOptions,
			method: "PUT",
			body: JSON.stringify({ ...config, status }),
		},
	})

	const response = (await result.json()) as UpdatePermalinkResponse
	return response
}

export const updateDraftWebstoreConfig = async (
	owner: string,
	webstoreName: string,
	config: WebstoreConfig,
): Promise<UpdatePermalinkResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/${owner}/webstore/${webstoreName}/draft`
	const result = await extendedFetch({
		url,
		options: {
			...defaultFetchOptions,
			method: "PUT",
			body: JSON.stringify(config),
		},
	})

	const response = (await result.json()) as UpdatePermalinkResponse
	return response
}

export const createWebstoreConfig = async (
	owner: string,
	webstoreName: string,
	currentStep: WebstoreConfigurationStep,
): Promise<CreatePermalinkResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/${owner}/webstore`
	const result = await extendedFetch({
		url,
		options: {
			...defaultFetchOptions,
			method: "POST",
			body: JSON.stringify({
				currentStep,
				webstoreName,
			}),
		},
	})

	const response = (await result.json()) as CreatePermalinkResponse
	return response
}

export const getImageUploadUrl = async (owner: string): Promise<GetImageUploadUrlsResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/owner/${owner}/imageUploadUrls`
	const path = ["webstore", owner]
		.map((part) => part.trim())
		.filter((part) => part)
		.join("/")
	const result = await extendedFetch({
		url,
		options: {
			...defaultFetchOptions,
			method: "POST",
			body: JSON.stringify({
				paths: [path],
			}),
		},
	})

	throwIfNot2xx(result)

	const response = (await result.json()) as GetImageUploadUrlsResponse
	return response
}

export const uploadImage = async (url: string, base64String: string): Promise<Response> => {
	const blob = base64JpegToBlob(removeBase64Prefix(base64String))
	const response = await fetch(url, {
		method: "PUT",
		body: blob,
	})

	throwIfNot2xx(response)

	return response
}

export const getWebstoreConfigByName = async (name: string): Promise<GetPermalinkResponse> => {
	const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/permalink/v1/ecommStoreName/${name}/type/${PermalinkType.PaymentPage}/typeId/${name}`
	const result = await fetch(url, {
		...defaultFetchOptions,
		method: "GET",
	})

	throwIfNot2xx(result)

	const response = (await result.json()) as GetPermalinkResponse
	return response
}
