import { getProductsFromCookies, getServicesFromCookies } from "@/api/clientSafe"
import { getStaleTimeInSeconds } from "@/utils"
import { useQuery } from "@tanstack/react-query"
import { useUserDetails } from "./index"

/**
 * 10 minutes
 */
const defaultStaleTime = getStaleTimeInSeconds(60 * 10)

export const useGetProductsQuery = (merchantId: string, storeId: string, staleTime: number = defaultStaleTime) => {
	const getProductsQuery = useQuery({
		queryKey: [merchantId, storeId, "products"],
		queryFn: () => getProductsFromCookies(merchantId, storeId),
		staleTime,
		enabled: !!merchantId && !!storeId,
		retry: false,
	})

	return getProductsQuery
}

export const useProducts = () => {
	const { merchantId, store, isDataReady, errors } = useUserDetails()

	const getProductsQuery = useGetProductsQuery(merchantId ?? "", store?.id ?? "")

	const products = getProductsQuery.data ?? []
	const isLoading = getProductsQuery.isFetched === false || getProductsQuery.isFetching || isDataReady === false
	const isError = getProductsQuery.isError || errors.length > 0
	const retry = getProductsQuery.refetch

	return { products, isLoading, isError, retry }
}

export const useGetServicesQuery = (merchantId: string, storeId: string, staleTime: number = defaultStaleTime) => {
	const getServicesQuery = useQuery({
		queryKey: [merchantId, storeId, "services"],
		queryFn: () => getServicesFromCookies(merchantId, storeId),
		staleTime,
		enabled: !!merchantId && !!storeId,
		retry: false,
	})

	return getServicesQuery
}

export const useServices = () => {
	const { merchantId, store, isDataReady: ready, errors } = useUserDetails()

	const getServicesQuery = useGetServicesQuery(merchantId ?? "", store?.id ?? "")

	const services = getServicesQuery.data ?? []
	const isLoading = getServicesQuery.isFetched === false || getServicesQuery.isFetching || ready === false
	const isError = getServicesQuery.isError || errors.length > 0
	const retry = getServicesQuery.refetch

	return { services, isLoading, isError, retry }
}
