import { useUserDetails } from "@/hooks/user"
import { useGetWebstoreConfigByOwner } from "@/hooks/webstore"
import { formControlAtom } from "@/state"
import { SocialPages } from "@/types"
import { useAtomValue } from "jotai"
import { useEffect } from "react"

export const useSocialsValues = (touched = false) => {
	const { owner } = useUserDetails()

	const { data } = useGetWebstoreConfigByOwner(owner ?? "")

	const socialPagesDraft = data?.webstoreConfigDraft?.socialPages

	const { handleOnChange, values } = useAtomValue(formControlAtom)

	const { facebook: formFacebook, tiktok: formTikTok, instagram: formInstagram } = values.socialPages as SocialPages
	const { facebook: draftFacebook, instagram: draftInstagram, tiktok: draftTiktok } = socialPagesDraft ?? {}

	useEffect(() => {
		// on initial load, update the atom
		const updatedSocials: SocialPages = {
			facebook: touched ? formFacebook : draftFacebook,
			tiktok: touched ? formTikTok : draftTiktok,
			instagram: touched ? formInstagram : draftInstagram,
		}
		handleOnChange("socialPages")(updatedSocials)
	}, [])

	useEffect(() => {}, [formFacebook, formTikTok, formInstagram])

	return {
		facebook: formFacebook,
		tiktok: formTikTok,
		instagram: formInstagram,
	}
}
