import { Routes } from "@/enums"
import { SESSION_EXPIRED_EVENT } from "@/utils"

type ExtendedFetchArguments = {
	url: string
	/**
	 * If not provided, the default options will be used.
	 */
	options?: RequestInit
	/**
	 * Executes before the 401 response is handled.
	 * @param response Response receuved from the fetch call
	 * @returns
	 */
	beforeHandle?: (response: Response) => Promise<void>
}

export const defaultFetchOptions: RequestInit = {
	headers: {
		"Content-Type": "application/json",
	},
	method: "GET",
	credentials: "include",
	cache: "no-cache",
}

export const getAuthUrl = (callbackUrl?: string) => {
	// Define the base URL based on the environment
	const baseURL: string = String(process.env.NEXT_PUBLIC_AUTH_DOMAIN_URL)

	// URL encode the callbackUrl and redirectUri
	const { origin, pathname } = window.location
	const encodedCallbackUrl: string = encodeURIComponent(callbackUrl ?? origin + pathname)
	const appCode = "merchant-dashboard-client"

	// Construct the authentication redirection URL
	return `${baseURL}/?appCode=${appCode}&mode=login&channel=Web&callbackUrl=${encodedCallbackUrl}`
}

export enum FetchErrorMessages {
	Unauthorized = "Unauthorized",
}

/**
 * Because we rely on a cookie to authenticate our API calls, and we cannot check the cookie from code,
 * we rely on the 401 status code to determine if the cookie is stale.
 * All the API calls we make should receive 401 if the cookie is stale.
 */
export async function extendedFetch({ url, options = defaultFetchOptions, beforeHandle }: ExtendedFetchArguments) {
	const response = await fetch(url, options)

	if (response.status === 401) {
		if (beforeHandle) {
			await beforeHandle(response)
		}
		// Trigger the modal by updating the context
		if (
			typeof window !== "undefined" &&
			(window.location.pathname.startsWith(Routes.Setup_Name) ||
				window.location.pathname.startsWith(Routes.Setup_Catalogue) ||
				window.location.pathname.endsWith(Routes.Manage_Builder))
		) {
			window.dispatchEvent(new CustomEvent(SESSION_EXPIRED_EVENT))
		}
		throw new Error(FetchErrorMessages.Unauthorized)
	}

	return response
}

export const isUnauthorizedError = (errors: Array<Error | null>) =>
	errors.some((e) => e?.message === FetchErrorMessages.Unauthorized)

export const getStaleTimeInSeconds = (seconds: number) => 1000 * seconds

export const getCreateCatalogueItemUrl = (location: "product" | "service") => {
	const dashboardURL: string = String(process.env.NEXT_PUBLIC_MERCHANT_DASHBOARD_REDIRECT)

	const { origin, pathname } = window.location

	return `${dashboardURL}/my-products-and-services/create-${location}?returnPath=${origin}${pathname}`
}
