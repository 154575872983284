import { CardProps } from "@/types"
import { defaultCatalogueType, defaultCategory } from "@/utils/catalogue"

export const filterCardsFn = (selectedType: string, selectedCategory: string) =>
  (cards: CardProps[]) =>
    cards.filter(({ category, type }) => {
      const matchCategory = selectedCategory === category || selectedCategory === defaultCategory
      const matchType = selectedType === type || selectedType === defaultCatalogueType

      return matchCategory && matchType
    })

export type SortFn = (catalogue: CardProps[]) => CardProps[]

export const sortByCreatedAt: SortFn = (cards: CardProps[]): CardProps[] => (
  [...cards].sort((a, b) => (
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  ))
)

export const sortByNameAsc: SortFn = (cards: CardProps[]): CardProps[] => (
  [...cards].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
)

export const sortByNameDesc: SortFn = (cards: CardProps[]): CardProps[] => (
  [...cards].sort((a, b) => b.name.localeCompare(a.name))
)

export const sortByPriceAsc: SortFn = (cards: CardProps[]): CardProps[] => (
  [...cards].sort((a, b) => a.price - b.price)
)

export const sortByPriceDesc: SortFn = (cards: CardProps[]): CardProps[] => (
  [...cards].sort((a, b) => b.price - a.price)
)

export const sortByOptions = [
  {
    label: 'Default',
    value: 'default',
    sortFn: sortByCreatedAt
  },
  {
    label: 'Alphabetically: A-Z',
    value: 'nameAsc',
    sortFn: sortByNameAsc
  },
  {
    label: 'Alphabetically: Z-A',
    value: 'nameDesc',
    sortFn: sortByNameDesc
  },
  {
    label: 'Price: Highest to lowest',
    value: 'priceDesc',
    sortFn: sortByPriceDesc
  },
  {
    label: 'Price: Lowest to highest',
    value: 'priceAsc',
    sortFn: sortByPriceAsc
  },
]

export const getUniqueCategoriesList = (cards: CardProps[]): { value: string, label: string }[] => {
  const categoryMap = cards.reduce(
    (acc, { category }) => {
      if (acc[category]) {
        acc[category] = acc[category] + 1
        return acc
      }

      acc[category] = 1
      return acc
    },
    {} as Record<string, number>
  )

  const categories = Object.entries(categoryMap)
    .map(([category, count]) => ({ value: category, label: `${category} (${count})` }))
    .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

  return [{ value: defaultCategory, label: defaultCategory }, ...categories]
}
