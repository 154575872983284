import Attention from "@/assets/Attention"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

type ErrorModalProps = {
	header?: string
	description?: string | React.ReactNode
	handlerText?: string
	handleRetry?: () => void
	elevation?: number
}
const ErrorModal = ({ description, handleRetry, handlerText, header, elevation = 3 }: ErrorModalProps) => {
	return (
		<Paper
			elevation={elevation}
			sx={{
				padding: "2rem 2.25rem",
				width: "23.5rem",
				justifyContent: "center",
				alignContent: "center",
			}}
		>
			<Stack gap={"1.5rem"} textAlign={"center"} alignItems={"center"}>
				<Attention width={150} height={150} />
				<Typography variant="h6" textAlign={"center"}>
					{header}
				</Typography>
				{typeof description === "string" ? <Typography>{description}</Typography> : description}
				{handleRetry && handlerText && (
					<Button fullWidth onClick={handleRetry}>
						{handlerText}
					</Button>
				)}
			</Stack>
		</Paper>
	)
}
export default ErrorModal
