/**
 * Throws an error if the response status is not in the 2xx range.
 *
 * @param {Response} response - The response object to check.
 * @throws {Error} If the response status is not in the 2xx range.
 */
export const throwIfNot2xx = (response: Response) => {
	if (!response.status || response.status < 200 || response.status >= 300) {
		throw new Error(response.statusText)
	}
}
