import { Libraries, useJsApiLoader } from "@react-google-maps/api"
import React from "react"

declare global {
	interface Window {
		google?: typeof google
		maps?: typeof google.maps
	}
}

const libraries: Libraries = ["places"]

export const useInitiate = () => {
	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY ?? "",
		libraries,
		region: "za",
		version: "weekly",
	})

	const [places, setPlaces] = React.useState<typeof google.maps.places>()

	React.useEffect(() => {
		if (globalThis.window?.google?.maps?.importLibrary && !places) {
			console.debug("Importing places library")
			globalThis.window.google.maps.importLibrary("places").then(() => {
				console.debug("Places library imported")
				setPlaces(globalThis.window.google.maps.places)
			})
		}
	}, [isLoaded])

	return {
		isLoaded,
		loadError,
		places,
	}
}

const getAddressComponent = (place: google.maps.places.PlaceResult, type: string): string => {
	if (!place.address_components) return ""
	const component = place.address_components.find((c) => c.types.includes(type))
	return component ? component.long_name : ""
}

export const useSelectedPlace = () => {
	const { loadError, isLoaded, places } = useInitiate()
	const [placeService, setPlaceService] = React.useState<google.maps.places.PlacesService | undefined>()

	React.useEffect(() => {
		if (isLoaded && !loadError && places) {
			setPlaceService(new places.PlacesService(document.createElement("div")))
		}
	}, [loadError, places, isLoaded])

	const getAddress = (placeId: google.maps.places.AutocompletePrediction["place_id"], resultCb: (address: any) => void) => {
		placeService?.getDetails({ placeId }, (result, status) => {
			if (result && status === google.maps.places.PlacesServiceStatus.OK) {
				const streetAddress = `${getAddressComponent(result, "street_number")} ${getAddressComponent(result, "route")}`
				const suburb = getAddressComponent(result, "sublocality_level_1") || getAddressComponent(result, "sublocality_level_2")
				const province = getAddressComponent(result, "administrative_area_level_1")
				const city = getAddressComponent(result, "locality")
				const zipCode = getAddressComponent(result, "postal_code")

				resultCb({ streetAddress, suburb, province, city, zipCode })
			}
		})
	}

	return getAddress
}

export const useAddressPredictions = () => {
	const { loadError, isLoaded, places } = useInitiate()
	const [autoCompleteService, setAutoCompleteService] = React.useState<google.maps.places.AutocompleteService>()
	const [predictions, setPredictions] = React.useState<google.maps.places.AutocompletePrediction[]>([])

	React.useEffect(() => {
		if (isLoaded && !loadError && places) {
			setAutoCompleteService(new places.AutocompleteService())
		}
	}, [loadError, isLoaded, places])

	const addressChange = React.useCallback(
		(input: string) => {
			if (!autoCompleteService) {
				return
			}

			autoCompleteService.getPlacePredictions(
				{
					input,
					componentRestrictions: { country: "za" },
				},
				(predictions) => {
					setPredictions(predictions || [])
				},
			)
		},
		[autoCompleteService],
	)

	const clear = () => setPredictions([])

	return { predictions, addressChange, clear }
}
